import Layout from "../containers/layout";
import React from "react";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>Page Not Found</h1>
  </Layout>
);

export default NotFoundPage;
